import { PointStyle } from 'chart.js';
import { LegendModel } from '../services/graph-management/models/legend-model';
import { CoreConstants } from '../constants/core-constants';
import { GraphDataTelemetry } from '../services/graph-management/interfaces/graph-data-telemetry.interface';
import { SensorType } from '../services/graph-management/enums/sensor-type.enum';

export const graphSeriesColor = (length: number): string => {
  const random = length % 20;
  return graphSeriesColors[random];
};

export const graphPointStyle = (length: number): PointStyle => {
  const random = length % 8;
  return graphPointStyles[random];
};

export const graphDashStyle = (length: number): number[] => {
  const random = (length % 5) * 5;
  return [random, random];
};

const graphSeriesColors: string[] = [
  '#ffcc00',
  '#434348',
  '#90ED7D',
  '#7CA1C4',
  '#F15B80',
  '#8085E9',
  '#2C908F',
  '#F7A35B',
  '#F45B5C',
  '#90E8E0',
  '#E56DB1',
  '#04BFB2',
  '#E3D354',
  '#94A596',
  '#AC3D91',
  '#5D3854',
  '#2C908F',
  '#D3273E',
  '#7CB4EC',
  '#106000',
];

export const graphPointStyles: PointStyle[] = [
  'circle',
  'rect',
  'triangle',
  'star',
  'cross',
  'rectRounded',
  'crossRot',
  'rectRot',
];

export const createLegends = (result: Map<string, GraphDataTelemetry[]>): LegendModel[] =>
  Array.from(result.keys()).map((device, i) => ({
    id: result.get(device)![0].deviceId,
    name: device,
    color: graphSeriesColor(i),
    legendShape: graphPointStyle(i),
    selected: true,
    dashArray: graphDashStyle(i),
    markerShape: graphPointStyle(i),
  }));

export const createExternalWeatherLegend = (index: number): LegendModel => ({
  id: CoreConstants.EXTERNAL_WEATHER_LEGEND,
  name: CoreConstants.EXTERNAL_WEATHER_LEGEND,
  color: graphSeriesColor(index),
  legendShape: graphPointStyle(index),
  selected: true,
  dashArray: graphDashStyle(index),
  markerShape: graphPointStyle(index),
});

const SensorTypeToPosition = (sensorType: SensorType) => {
    switch (sensorType) {
        case SensorType.CO2:
          return 1;
        case SensorType.ParticulateMatter10:
            return 2;
        case SensorType.ParticulateMatter2_5:
            return 3;
        case SensorType.ParticulateMatter1_0:
            return 4;
        case SensorType.Temperature:
            return 5;
        case SensorType.Humidity:
            return 6;
        case SensorType.PalletWrapped:
            return 7;
        case SensorType.SoC:
            return 8;
        case SensorType.SoH:
            return 9;
        case SensorType.Battery:
            return 10;
        case SensorType.Current:
            return 11;
        case SensorType.PalletAggregation:
            return 12;
        case SensorType.ParticulateMatter:
            return 13;
        case SensorType.DevicesCount:
            return 14;
        default: return -1;
    }
};

export const SensorStandardSorter = (a: SensorType, b: SensorType) =>
    SensorTypeToPosition(a) - SensorTypeToPosition(b);

export const SensorByPrioritySorter = (priority: SensorType[]) => (a: SensorType, b: SensorType) =>
  priority.includes(a) && priority.includes(b)
    ? priority.indexOf(a) - priority.indexOf(b)
    : priority.indexOf(b) - priority.indexOf(a);
