import { InventoryDeviceOverview, InventoryTrackingService } from '@dpdhl-iot/api/backend';
import { CoreConstants } from '@dpdhl-iot/shared';
import { IotApplicationModel } from '@dpdhl-iot/api/management';
import { filter } from 'rxjs/operators';
import { map, Observable } from 'rxjs';
import { ApplicationDataService } from '@dpdhl/iot-shared-ui';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InventoryTrackingUsageOverviewService {
  private selectedApplication: IotApplicationModel;

  constructor(
    private applicationDataService: ApplicationDataService,
    private inventoryTrackingService: InventoryTrackingService,
  ) {
    this.applicationDataService.application$
      .pipe(
        filter((a) => !!a.application),
        map((a) => a.application as IotApplicationModel),
      )
      .subscribe((app) => {
        this.selectedApplication = app;
      });
  }

  public getDeviceOverview(from: number, until: number): Observable<InventoryDeviceOverview[]> {
    return this.inventoryTrackingService.getDeviceOverview(CoreConstants.API_VERSION, {
      applicationId: this.selectedApplication.id,
      applicationGuId: this.selectedApplication.uuid,
      from,
      until,
    });
  }
}
