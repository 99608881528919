import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  LegendModel,
  CoreConstants,
  createExternalWeatherLegend,
  GraphManagementService,
} from '@dpdhl-iot/shared';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-graph-legend',
  templateUrl: './graph-legend.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphLegendComponent implements OnDestroy, OnChanges {
  @Input() legends: LegendModel[] = [];
  @Input() showExternalWeather: boolean;
  @Input() toggleWeather: boolean;
  @Input() filter: string[] | null = null;
  @Output() toggleAlerts = new EventEmitter<boolean>();

  public formGroup: FormGroup;
  private subscriptions: Subscription[] = [];

  private readonly allDevicesSelectedHint = 'room.chart.unselectAllHint';
  private readonly allDevicesUnselectedHint = 'room.chart.selectAllHint';

  constructor(private graphManagementService: GraphManagementService) {
    this.formGroup = new FormGroup({
      allDeviceCheckbox: new FormControl<boolean>(true),
      alertsOnChart: new FormControl<boolean>(false),
      externalWeather: new FormControl<boolean>(false),
    });
    this.subscriptions.push(
      this.formGroup.controls.allDeviceCheckbox.valueChanges.subscribe((value) => {
        this.toggleAll(value);
      }),
      this.formGroup.controls.alertsOnChart.valueChanges.subscribe((value) => {
        this.toggleAlerts.emit(value);
      }),
      this.formGroup.controls.externalWeather.valueChanges.subscribe((value) => {
        this.toggleExternalWeather(value);
      })
    );
  }

  get data(): LegendModel[] {
    return this.filter?.length ? this.legends.filter((l) => l.selected) : this.legends;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter && !changes.filter.firstChange) {
      this.applyFilter();
    }
    if (
      changes.toggleWeather &&
      !changes.toggleWeather.firstChange &&
      !changes.toggleWeather.currentValue
    ) {
      this.formGroup.controls.externalWeather.setValue(false);
    }
  }

  applyFilter() {
    const new_legends = [...this.legends];
    new_legends.forEach((l) => {
      l.selected = !this.filter?.length || this.filter.includes(l.id);
    });
    this.graphManagementService.setGraphLegendData(new_legends);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  toggle(shape: LegendModel) {
    if (!this.filter) {
      shape.selected = !shape.selected;
      this.setAllDeviceCheckboxState(shape.selected);
      this.graphManagementService.setGraphLegendData(this.legends);
    }
  }

  toggleAll(value: boolean) {
    const new_legends = [...this.legends];
    new_legends.forEach((shape) => (shape.selected = value));
    this.graphManagementService.setGraphLegendData(new_legends);
  }

  public getAllDeviceCheckboxHint() {
    return this.formGroup.controls.allDeviceCheckbox.value
      ? this.allDevicesSelectedHint
      : this.allDevicesUnselectedHint;
  }

  private setAllDeviceCheckboxState(isShapeSelected: boolean) {
    const value = !isShapeSelected ? false : !this.legends.find((shape) => !shape.selected);
    this.formGroup.controls.allDeviceCheckbox.setValue(value, { emitEvent: false });
  }

  private toggleExternalWeather(value: boolean) {
    const filtered = this.legends.filter((d) => d.id !== CoreConstants.EXTERNAL_WEATHER_LEGEND);
    let new_legends = filtered;
    if (value) {
      new_legends = [...filtered, createExternalWeatherLegend(this.legends.length)];
    }
    this.graphManagementService.setGraphLegendData(new_legends);
  }
}
