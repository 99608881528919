import { Injectable } from '@angular/core';
import {
  AbstractUserManagementMapperService,
  UserManagementAccessGroupsAndRoles,
  UserManagementRole,
  UserManagementAccessRoles,
  ExtendedHierarchyTreeModel,
  HierarchyNodeType,
  UserGroupModel,
} from '@dpdhl/iot-shared-ui';
import { ViewConfigurationService } from '../view-configuration/view-configuration.service';

@Injectable({ providedIn: 'root' })
export class ApplicationUserManagementMapperService extends AbstractUserManagementMapperService {
  accessGroupsRoles!: UserManagementAccessGroupsAndRoles[];
  isPredictiveMaintenance = false;

  constructor(private viewConfigurationService: ViewConfigurationService) {
    super();

    this.viewConfigurationService.currentViewConfiguration$.subscribe((viewConfiguration) => {
      this.isPredictiveMaintenance = viewConfiguration.predictiveMaintenance;
    });
  }

  getAccessGroupsAndRoles(
    hierarchy: ExtendedHierarchyTreeModel,
    manageableUserGroups: UserGroupModel[],
  ): UserManagementAccessGroupsAndRoles[] {
    this.accessGroupsRoles = [];
    this.mapHierarchyToDropdown(hierarchy, manageableUserGroups);
    return this.accessGroupsRoles;
  }

  toAccessGroupAndRoles(userGroupIds: string[]): UserManagementAccessRoles[] {
    const facilityRoles: UserManagementAccessRoles[] = [];
    userGroupIds.forEach((userGroupId) => {
      const facility = this.accessGroupsRoles.find((facility) => {
        const role = facility.roles.find((role) => role.id === userGroupId);
        return role;
      });
      const existing = facilityRoles.find((role) => role.accessGroupId === facility?.accesGroupId);
      if (facility) {
        if (existing) {
          existing.roleIds.push(userGroupId);
        } else {
          facilityRoles.push({
            roleIds: [userGroupId],
            accessGroupId: facility?.accesGroupId ?? '',
          });
        }
      }
    });
    return facilityRoles;
  }

  toAccessGroupRole(groupId: string): UserManagementRole {
    let role: UserManagementRole | undefined;
    const facility = this.accessGroupsRoles.find((facility) => {
      role = facility.roles.find((role) => role.id === groupId);
      return role;
    });
    return role
      ? { ...role, displayName: `${facility?.displayName} - ${role.displayName}` }
      : { displayName: `${facility?.displayName}`, id: groupId };
  }

  toUserGroupIds(userGroup: UserManagementRole[]): string[] {
    return userGroup.map((group) => group.id);
  }

  override addAccessBtnVisible(): boolean {
    return !this.isPredictiveMaintenance;
  }

  private mapHierarchyToDropdown(
    node: ExtendedHierarchyTreeModel,
    manageableUserGroups: UserGroupModel[],
    level = 0,
  ): void {
    const customEntityNodes = node.children.filter(
      (node) =>
        !(
          node.nodeType === HierarchyNodeType.USER_GROUP ||
          node.nodeType === HierarchyNodeType.DEVICE_ACCESS_GROUP ||
          node.customEntityExternalType
        ),
    );

    const userGroupNodes = node.children.filter(
      (node) =>
        node.nodeType === HierarchyNodeType.USER_GROUP &&
        manageableUserGroups.some((u) => u.id === node.id),
    );

    if (userGroupNodes.length > 0) {
      userGroupNodes.sort((n1, n2) => (n1.name > n2.name ? 1 : -1));
      this.accessGroupsRoles.push({
        displayName: this.getEntityName(node.name, level),
        level: level,
        accesGroupId: node.id,
        roles: userGroupNodes.map((childNode) => ({
          displayName: this.getUserGroupName(childNode.name),
          id: childNode.id,
        })),
      });
    }

    customEntityNodes.sort((n1, n2) => (n1.name > n2.name ? 1 : -1));
    customEntityNodes.forEach((childNode) => {
      this.mapHierarchyToDropdown(childNode, manageableUserGroups, level + 1);
    });
  }

  private getUserGroupName(name: string): string {
	if (/^(.*)- User ([Mm])anager$/.test(name)) {
      return 'User Manager';
    } else if (/^(.*)- Device ([Mm])anager$/.test(name)) {
        return 'Device Manager';
    } else {
      return /^(.*)- ([Mm])anager$/.test(name) ? 'Manager' : 'Reader';
    }
  }

  private getEntityName(name: string, level: number): string {
    switch (level) {
      case 0:
        return `Use case ${name}`;
      case 4:
        return `Facility ${name}`;
      case 5:
        return `Area ${name}`;
    }
    return name;
  }
}
