import { Injectable } from '@angular/core';
import { map, Subject } from 'rxjs';
import { GraphDataTelemetry } from './interfaces/graph-data-telemetry.interface';
import { SensorType } from './enums/sensor-type.enum';
import * as _ from 'lodash';
import { ApplicationFacilityService } from '../application-facility/application-facility.service';
import { LegendModel } from './models/legend-model';
import {
  CoreConstants,
  createLegends,
  graphDashStyle,
  GraphDataDevice,
  graphSeriesColor,
  ViewConfigurationModel,
} from '@dpdhl-iot/shared';
import {
  AggregateRequestModelAggregateByInner,
  AggregateRequestParams,
  AggregateResponseModel,
  Aggregation,
  AnalyticsTelemetryService,
  QueryRequestParams,
} from '@dpdhl-iot/api/data';
import { DeviceDetails, FilterOperator } from '@dpdhl-iot/api/backend';
import { PointStyle } from 'chart.js/dist/types';

@Injectable({
  providedIn: 'root',
})
export class GraphManagementService {
  private telemetryDataAggregationSubject = new Subject<
    Map<string, Array<AggregateResponseModel & GraphDataTelemetry>>
  >();
  private telemetryDataQuerySubject = new Subject<GraphDataDevice[]>();
  private telemetryDataChangeSubject = new Subject<boolean>();
  private graphLegendDataSubject = new Subject<LegendModel[]>();
  private facilityDevices: DeviceDetails[] = [];

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public telemetryDataChangeNotifier = this.telemetryDataChangeSubject.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public telemetryDataAggregationProvider = this.telemetryDataAggregationSubject.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public graphLegendData = this.graphLegendDataSubject.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public telemetryDataQueryProvider = this.telemetryDataQuerySubject.asObservable();
  private legendData: LegendModel[] = [];

  constructor(
    private applicationFacilityService: ApplicationFacilityService,
    private analyticsTelemetryService: AnalyticsTelemetryService,
  ) {
    this.applicationFacilityService.facilityDevices.subscribe((result) => {
      this.facilityDevices = result;
    });
  }

  refreshAggregateTelemetryService(): void {
    this.telemetryDataChangeSubject.next(true);
  }

  readTelemetryAggregationData(
    viewConfiguration: ViewConfigurationModel,
    deviceGroupId: string | undefined,
    devices: DeviceDetails[],
    dateRange: Date[],
    resetLegend: boolean,
  ): void {
    if (resetLegend) {
      this.setGraphLegendData([]);
    }

    const aggregateRequest = this.getAggregateRequestModel(
      viewConfiguration,
      deviceGroupId,
      devices,
      dateRange,
    );
    this.telemetryDataChangeSubject.next(true);
    this.analyticsTelemetryService.aggregate(aggregateRequest).subscribe((res) => {
      const graphRawData = res.reduce(
        (accumulatedDevice, currentDevice) =>
          this.accumulateDevices(
            accumulatedDevice,
            currentDevice as AggregateResponseModel & GraphDataTelemetry,
          ),
        new Map<string, Array<AggregateResponseModel & GraphDataTelemetry>>(),
      );

      for (const deviceId of graphRawData.keys()) {
        graphRawData.get(deviceId)!.forEach((aggData) => {
          aggData.aggregateData!.forEach((aggregate) => {
            _.set(
              aggData,
              aggregate
                .property!.replace(
                  'particulateMatterConcentration1.0',
                  'particulateMatterConcentration1_0',
                )
                .replace('particulateMatterConcentration2.5', 'particulateMatterConcentration2_5'),
              aggregate.value,
            );
          });
        });
      }

      this.telemetryDataAggregationSubject.next(graphRawData);

      if (resetLegend) {
        this.setGraphLegendData(createLegends(graphRawData));
      } else {
        this.setGraphLegendData(this.legendData);
      }
    });
  }

  getTelemetryQuery(
    sensorType: SensorType,
    selectedDateRange: Date[],
    applicationId: string,
    zoneIds: string[],
    inTransitCutOffThreshold: number | undefined,
  ): void {
    const inTransitThreshold = inTransitCutOffThreshold ? inTransitCutOffThreshold * 1000 : 900000;

    const queryRequest: QueryRequestParams = {
      xApiVersion: CoreConstants.API_VERSION,
      queryRequest: {
        queryPipes: [
          {
            filter: {
              and: [
                {
                  propertyName: 'deviceTimestamp',
                  operator: FilterOperator.GREATER_THAN,
                  value: selectedDateRange[0].getTime(),
                },
                {
                  propertyName: 'deviceTimestamp',
                  operator: FilterOperator.LESS_EQUAL,
                  value: selectedDateRange[1].getTime(),
                },
                {
                  propertyName: 'applicationId',
                  operator: FilterOperator.EQUAL,
                  value: applicationId,
                },
                {
                  or: zoneIds.map((zoneId) => ({
                    propertyName: 'zones',
                    operator: FilterOperator.CONTAINS,
                    value: zoneId,
                  })),
                },
              ],
            },
          },
          {
            SummarizeParameters: [
              {
                PropertyName: 'deviceId',
                Aggregation: 'DistinctCount',
              },
            ],
            GroupByExpressions: [
              {
                PropertyName: 'deviceTimestamp',
                RoundTo: inTransitThreshold,
              },
            ],
          },
          {
            SortParameters: [{ PropertyName: 'deviceTimestamp', SortDirection: 'Desc' }],
          },
        ],
      },
    };

    interface InventoryDeviceMessage {
      deviceTimestamp: number;
      dcount_deviceId: number;
    }

    this.analyticsTelemetryService
      .query(queryRequest)
      .pipe(map((result) => result as InventoryDeviceMessage[]))
      .subscribe((result) => {
        const graphDataDevices: GraphDataDevice = {
          key: sensorType,
          value: [],
        };

        let prevMessage: InventoryDeviceMessage | null = null;

        result.forEach((message) => {
          if (prevMessage) {
            const difference = prevMessage.deviceTimestamp - message.deviceTimestamp;
            if (difference > inTransitThreshold) {
              graphDataDevices.value.push({
                x: new Date(prevMessage.deviceTimestamp - inTransitThreshold),
                y: 0,
              });
              graphDataDevices.value.push({
                x: new Date(message.deviceTimestamp + inTransitThreshold),
                y: 0,
              });
            }
          }

          graphDataDevices.value.push({
            x: new Date(message.deviceTimestamp),
            y: message.dcount_deviceId,
          });

          prevMessage = message;
        });

        if (graphDataDevices) {
          this.telemetryDataQuerySubject.next([graphDataDevices]);
        }

        const legendShape: PointStyle = 'circle';
        const dashArray = graphDashStyle(0);
        const markerShape: PointStyle = 'circle';
        const color = graphSeriesColor(0);
        const legend: LegendModel = {
          id: sensorType,
          name: sensorType,
          color,
          legendShape,
          selected: true,
          dashArray,
          markerShape,
        };
        this.setGraphLegendData([legend]);
      });
  }

  getAggregateRequestModel(
    viewConfiguration: ViewConfigurationModel,
    deviceGroupId: string | undefined,
    devices: DeviceDetails[],
    dateRange: Date[],
  ): AggregateRequestParams {
    let aggregationTime: number;
    let aggregateBy: AggregateRequestModelAggregateByInner[];

    const areaChartSensorTypes = devices
      .map((device) =>
        device.deviceSensorTypes!.map((sensor) => sensor.deviceSensorType!.toString()),
      )
      .reduce((prev, curr) => prev.concat(curr), [])
      .filter((elem, index, self) => index === self.indexOf(elem));

    if (viewConfiguration.graph.show_pnpBatteryComo) {
      aggregationTime = CoreConstants.TEN_MINUTES_INTERVAL;
      aggregateBy = [
        { aggregation: Aggregation.MAX, propertyPath: 'sensorMeasurements.temperature' },
        { aggregation: Aggregation.MIN, propertyPath: 'properties.generalInformation.soC' },
        { aggregation: Aggregation.MIN, propertyPath: 'properties.generalInformation.soH' },
        { aggregation: Aggregation.MAX, propertyPath: 'properties.generalInformation.current' },
      ];
    } else if (viewConfiguration.graph.show_dscWrappingRobots) {
      aggregationTime = CoreConstants.TEN_MINUTES_INTERVAL;
      aggregateBy = [
        {
          aggregation: Aggregation.MAX,
          propertyPath: this.getKeyForSensorType(SensorType.PalletAggregation),
        },
        {
          aggregation: Aggregation.MIN,
          propertyPath: this.getKeyForSensorType(SensorType.Battery),
        },
      ];
    } else {
      aggregationTime = 1000;
      aggregateBy = areaChartSensorTypes.map<AggregateRequestModelAggregateByInner>((s) => ({
        aggregation: Aggregation.MAX,
        propertyPath: this.getKeyForSensorType(s),
      }));
    }

    return {
      xApiVersion: CoreConstants.API_VERSION,
      aggregateRequest: {
        filter: {
          and: [
            {
              propertyName: 'deviceTimestamp',
              operator: FilterOperator.GREATER_THAN,
              value: dateRange[0].getTime(),
            },
            {
              propertyName: 'deviceTimestamp',
              operator: FilterOperator.LESS_EQUAL,
              value: dateRange[1].getTime(),
            },
            {
              propertyName: 'applicationId',
              operator: FilterOperator.EQUAL,
              value: viewConfiguration.application.id,
            },
            {
              propertyName: 'deviceAccessGroupId',
              operator: FilterOperator.EQUAL,
              value: deviceGroupId,
            },
          ],
        },
        aggregateBy,
        aggregationTime,
      },
    };
  }

  refreshQueryTelemetryService(): void {
    this.telemetryDataQuerySubject.next([]);
  }

  public setGraphLegendData(legendData: LegendModel[]) {
    this.legendData = legendData;
    this.graphLegendDataSubject.next(legendData);
  }

  getKeyForSensorType(sensorType: string): string {
    switch (sensorType) {
      case SensorType.Battery:
        return 'sensorMeasurements.batteryPercentage';
      case SensorType.CO2:
        return 'sensorMeasurements.carbonDioxideLevel';
      case SensorType.Current:
        return 'properties.generalInformation.current';
      case SensorType.Humidity:
        return 'sensorMeasurements.humidity';
      case SensorType.PalletWrapped:
      case SensorType.PalletAggregation:
        return 'properties.machineCounters.tot_completed_packing_cycles';
      case SensorType.Temperature:
        return 'sensorMeasurements.temperature';
      case SensorType.SoC:
        return 'properties.generalInformation.soC';
      case SensorType.SoH:
        return 'properties.generalInformation.soH';
      case SensorType.ParticulateMatter10:
        return 'properties.particulateMatterConcentration10';
      case SensorType.ParticulateMatter2_5:
        return 'properties.particulateMatterConcentration2.5';
      case SensorType.ParticulateMatter1_0:
        return 'properties.particulateMatterConcentration1.0';
    }
    return '';
  }

  protected accumulateDevices(
    accumulatedDevices: Map<string, Array<AggregateResponseModel & GraphDataTelemetry>>,
    currentDevice: AggregateResponseModel & GraphDataTelemetry,
  ): Map<string, Array<AggregateResponseModel & GraphDataTelemetry>> {
    const deviceName = this.facilityDevices.find(
      (device) => device.deviceId === currentDevice.deviceId,
    )?.deviceName;
    const deviceNameKey = deviceName
      ? `${deviceName} (${currentDevice.deviceId})`
      : currentDevice.deviceId;

    accumulatedDevices.set(deviceNameKey, [
      ...(accumulatedDevices.get(deviceNameKey) ?? []),
      currentDevice,
    ]);
    return accumulatedDevices;
  }
}
