<div class="mb-12 mt-12 mr-12 grid grid-cols-4 space-x-12 space-y-12">
  <div class="ml-12 mt-12 col-span-3">
    <dpdhl-select
      _formControlName="deviceId"
      [_formGroup]="formGroup"
      label="{{ 'predictiveMaintenance.frequencyAnalysis.selectSensor' | translate }}"
    >
      <ng-container *ngFor="let sensor of predictiveDevices">
        <dpdhl-select-option
          [value]="sensor.deviceId"
          [display]="sensor.deviceName ?? sensor.deviceId"
        ></dpdhl-select-option>
      </ng-container>
    </dpdhl-select>
  </div>

  <div class="ml-12 mt-12 col-span-1 flex justify-items-stretch">
    <div class="">
      <dpdhl-input
        [_formGroup]="formGroup"
        _formControlName="cell"
        type="number"
        label="Zelle"
        [validators]="validatorsCell"
        unit="#"
      ></dpdhl-input>
    </div>

    <div class="justify-self-end ml-12 mt-16 w-24 h-24">
      <img *ngIf="alertForCell?.alertState === CellAlertSeverity.ALERT" class="icon" alt="alert"
           title="{{ 'predictiveMaintenance.frequencyAnalysis.alertOpenSince' | translate : { date: alertForCell.createDate | date:'dd.MM.yyyy HH:mm' } }}"
           src="assets/images/CoMo/cellalert-default-icon.svg"/>
      <img *ngIf="alertForCell?.alertState === CellAlertSeverity.WARNING" class="icon" alt="warning"
           title="{{ 'predictiveMaintenance.frequencyAnalysis.warningOpenSince' | translate : { date: alertForCell.createDate | date:'dd.MM.yyyy HH:mm' } }}"
           src="assets/images/CoMo/Alert_Circle.svg"/>
    </div>
  </div>

  <div class="col-span-1">
    <dpdhl-date-range-picker
      [_formGroup]="formGroup"
      label="{{ 'predictiveMaintenance.history.dateRange' | translate }}"
      _formControlName="dateRange"
      class="w-1/3 mr-32"
    ></dpdhl-date-range-picker>
  </div>

  <div class="ml-24 col-span-1">
    <div class="text-footnote text-secondary">
      {{ 'predictiveMaintenance.frequencyAnalysis.colorContrast' | translate }}
    </div>
    <dpdhl-slider
      [_formGroup]="formGroup"
      _formControlName="colorContrast"
      [showThumbLabel]="true"
      [min]="2"
      [max]="20"
    ></dpdhl-slider>
  </div>

  <div class="p-24 col-span-4 h-full" *ngIf="!loading">
    <dpdhl-tabs
      [active]="selectedTab"
      (select)="tabSelected($event)"
      data-test-id="tabs-element"
    >
      <dpdhl-tab-element *dpdhlTabElement>{{
          'predictiveMaintenance.frequencyAnalysis.frequencyCellAnalysis' | translate
        }}</dpdhl-tab-element>
      <dpdhl-tab-element *dpdhlTabElement>{{
          'predictiveMaintenance.frequencyAnalysis.frequencyTriggeringFactors' | translate
        }}</dpdhl-tab-element>
    </dpdhl-tabs>
  </div>

  <ng-container>
    <div class="p-24 col-span-4 m-64" [hidden]="selectedTab !== 0">
      <div class="h-full flex">
        <div
          data-test-id="overlayDate"
          (mousemove)="mouseMove($event)"
          (mouseleave)="overlayDateMouseLeave()">
          <app-frequency-diagram
            [frequencyRange]="frequencyRangeString"
            [loadFrequencyDiagram]="loadFrequencyDiagram"
            [showScale]="false"
            [showXAxis]="false"
            #frequencyImage>
          </app-frequency-diagram>
          <div class="relative -mt-32" *ngIf="(alertMarkGroups | keyvalue)?.length > 0">
            <div *ngFor="let remark of alertMarkGroups | keyvalue" class="group has-tooltip" [style.margin-left.px]="getMarginForImage(remark.key)">
              <img
                class="absolute h-20"
                alt="{{ remark.key | date: 'dd.MM.yyyy' }}"
                src="/assets/images/CoMo/info_outline.svg"/>
              <div class="custom-infobox shadow-lg bg-white overflow-auto rounded-4 text-body2 w-auto" [ngClass]="(getMarginForImage(remark.key) > 300)?'tooltip-left':'tooltip-right'">
                <div class='flex items-center justify-items-center bg-gray-100 px-8 uppercase space-x-16 py-8'>
                  <span class="flex-grow font-bold text-body1 whitespace-nowrap	">Date: {{ remark.key | date: 'dd.MM.yyyy' }}</span>
                  <dpdhl-svg-icon src="/assets/images/CoMo/info_outline.svg"></dpdhl-svg-icon>
                </div>
                <div class='p-8 grid grid-cols-7 gap-y-12 dpdhl-text-secondary'>
                  <dpdhl-svg-icon class="w-20" src="assets/images/CoMo/cellalert-default-icon.svg"></dpdhl-svg-icon>
                  <div class="col-span-5">New Alerts: </div>
                  <div class="text-right">{{getCountForAlertType(CellAlertStatus.NewAlert, remark.value)}}</div>

                  <dpdhl-svg-icon class="w-20" src="assets/images/CoMo/Alert_Circle.svg"></dpdhl-svg-icon>
                  <div class="col-span-5">New Warnings: </div>
                  <div class="text-right">{{getCountForAlertType(CellAlertStatus.NewWarning, remark.value)}}</div>

                  <dpdhl-svg-icon class="w-20 opacity-50" src="assets/images/CoMo/cellalert-default-icon.svg"></dpdhl-svg-icon>
                  <div class="col-span-5">Severity changed to Alert: </div>
                  <div class="text-right">{{getCountForAlertType(CellAlertStatus.SeverityToAlert, remark.value)}}</div>

                  <dpdhl-svg-icon class="w-20 opacity-50" src="assets/images/CoMo/Alert_Circle.svg"></dpdhl-svg-icon>
                  <div class="col-span-5">Severity changed to Warning: </div>
                  <div class="text-right">{{getCountForAlertType(CellAlertStatus.SeverityToWarning, remark.value)}}</div>

                  <dpdhl-svg-icon class="w-20" src="assets/images/CoMo/paper_registration.svg"></dpdhl-svg-icon>
                  <div class="col-span-5">Manually resolved: </div>
                  <div class="text-right">{{getCountForAlertType(CellAlertStatus.ManuallyResolved, remark.value)}}</div>

                  <dpdhl-svg-icon class="w-20" src="assets/images/CoMo/completed.svg"></dpdhl-svg-icon>
                  <div class="col-span-5">Auto resolved: </div>
                  <div class="text-right">{{getCountForAlertType(CellAlertStatus.AutomaticallyResolved, remark.value)}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-24 ml-24" *ngIf="!loading">
          <img *ngIf="noiseGradientImage" [src]="noiseGradientImage" alt="gradient"/>
        </div>
        <div class="w-full h-full" *ngIf="loading">
          <dpdhl-skeleton-card [classes]="['frequency-loading']"
          ><p class="p-8 font-bold-italic text-secondary text-body1">
            {{ 'predictiveMaintenance.frequencyAnalysis.loading' | translate }}
          </p></dpdhl-skeleton-card
          >
        </div>
      </div>
      <div class="mt-2 ml-[6.5rem] h-16 col-span-2">{{ mouseOverText }}</div>
    </div>

    <div class="p-24 col-span-4 m-64 h-full" [hidden]="selectedTab !== 1">
      <div class="text-headline1 col-span-3 mb-24 h-32">{{
          'predictiveMaintenance.frequencyAnalysis.alertFrequency' | translate
        }}</div>

      <app-extended-frequency-diagram
        [frequencyRange]="noiseFrequencyRange"
        [frequencyRangeString]="noiseFrequencyRangeString"
        [frequencyRangeStringScaled]="noiseFrequencyRangeStringScaled"
        [frequencyDataCellTimes]="noiseFrequencyDataCellTimes"
        [loadFrequencyDiagram]="loadNoiseAlertFrequencyDiagram"
      ></app-extended-frequency-diagram>

      <div class="text-headline1 col-span-3 mt-16 mb-24 h-32">{{
          'predictiveMaintenance.frequencyAnalysis.warningFrequency' | translate
        }}</div>

      <app-extended-frequency-diagram
        [frequencyRange]="noiseFrequencyRange"
        [frequencyRangeString]="noiseFrequencyRangeString"
        [frequencyRangeStringScaled]="noiseFrequencyRangeStringScaled"
        [frequencyDataCellTimes]="noiseFrequencyDataCellTimes"
        [loadFrequencyDiagram]="loadNoiseWarningFrequencyDiagram"
      ></app-extended-frequency-diagram>
    </div>
  </ng-container>
</div>
