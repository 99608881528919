import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { NotificationDataService, NotificationType } from '@dpdhl/iot-shared-ui';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private toastNotificationService: NotificationDataService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.toastNotificationService.addNotification({
          text: 'Request failed: ' + error.url,
          type: NotificationType.ERROR,
        });
        return throwError(error);
      })
    );
  }
}
