import { FileType, SensorType } from '@dpdhl-iot/api/backend';

export enum FacilityReportTypes {
  Facility = 'Facility Details',
  Raw = 'Raw Data',
  Alerts = 'Alerts',
  Lrt = 'LRT',
  LrtAggregated = 'LRT Aggregated'
}

export enum AreaReportTypes {
  Area = 'Area Details',
  Raw = 'Raw Data',
  Alerts = 'Alerts',
  Lrt = 'LRT',
  LrtAggregated = 'LRT Aggregated'
}

export interface ReportDownloadOptions {
  type: string;
  extension: FileType;
  range: Date[];
  devices: string[];
  sensors: SensorType[];
}
