<div>
      <div class="flex">
        <div data-test-id="overlayDate"
             (mousemove)="mouseMove($event)"
             (mouseleave)="overlayDateMouseLeave()">
          <app-frequency-diagram
            [frequencyRange]="frequencyRangeStringScaled"
            [loadFrequencyDiagram]="loadFrequencyDiagram"
            [showScale]="false"
            [showXAxis]="false"
            frequencyRangeContainerClass="mt-8">
          </app-frequency-diagram>
        </div>
      </div>
      <div class="mt-[-1.5rem] ml-[6.5rem] h-16 col-span-2">{{ mouseOverText }}</div>
</div>
