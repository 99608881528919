import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  languages = ['en', 'de'];

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    const browserLang = this.translateService.getBrowserLang() ?? '';
    if (this.languages.includes(browserLang)) {
      this.translateService.setDefaultLang(browserLang);
    } else {
      this.translateService.setDefaultLang('en');
    }
  }
}
