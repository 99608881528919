import { Injectable } from '@angular/core';
import { DeviceNotificationConfigModel } from '@dpdhl-iot/api/backend';
import { NotificationViewModel } from '@dpdhl-iot/shared';

@Injectable({
  providedIn: 'root',
})
export class NotificationMapperService {
  toRowEntry(data: NotificationViewModel): { [key: string]: string | number | Date } {
    return {
      'Application Id': data.applicationId ?? '',
      'Facility Name': data.facility,
      'Area Id': data.areaId,
      'Area Name': data.area,
      Recipients: [data.recipients, ...data.notificationConfigs.map((n) => n.recipients)]
        .flat()
        .map((a) => a.recipient)
        .join(', '),
      'User Name': [data.userName, ...data.notificationConfigs.map((n) => n.userName)].join(', '),
      'Notify on close': data.notifyOnClose ? 'Yes' : 'No',
    };
  }

  toNotificationViewModel(notificationConfigModel: DeviceNotificationConfigModel): NotificationViewModel {
    return {
      recipients: notificationConfigModel.recipients!,
      notificationConfigs: notificationConfigModel.notificationConfigs!.map((n) =>
        this.toNotificationViewModel(n)
      ),
      applicationId: notificationConfigModel.applicationId!,
      country: notificationConfigModel.country!,
      facility: notificationConfigModel.facility!,
      areaId: notificationConfigModel.areaId!,
      area: notificationConfigModel.area!,
      userName: notificationConfigModel.userName!,
      notifyOnClose: notificationConfigModel.notifyOnClose!,
      notificationConfigId: notificationConfigModel.notificationConfigId!,
      showAll: false,
      editable: false,
      deviceId: notificationConfigModel.deviceId,
      deviceName: notificationConfigModel.deviceName
    };
  }
}
