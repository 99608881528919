import {
  ViewConfigurationService,
  AlertViewModel,
  BaseAlertDataService,
  AlertChartModel,
} from '@dpdhl-iot/shared';
import { PredictiveMaintenanceAlertDataService } from '@dpdhl-iot/predictive-maintenance';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Filter, UpdateAlertStatusRequest } from '@dpdhl-iot/api/backend';

@Injectable({ providedIn: 'root' })
export class AlertDataService {
  private predictiveMaintenance = false;
  constructor(
    viewConfigurationService: ViewConfigurationService,
    private alertDataService: BaseAlertDataService,
    private predictiveMaintenanceAlertDataService: PredictiveMaintenanceAlertDataService,
  ) {
    viewConfigurationService.currentViewConfiguration$.subscribe((vc) => {
      this.predictiveMaintenance = vc.facilityView.show_predictiveMaintenance;
    });
  }

  calculateAlertsCount(): Observable<[number, number]> {
    return this.create().calculateAlertsCount();
  }

  fetchAlerts(
    removeValuePrefixes: boolean,
    deviceAccessGroupIds: string[] | null,
    filters: Filter[] | null = null,
    areaIds: string[] | null = null,
  ): Observable<AlertViewModel[]> {
    return this.create().fetchAlerts(removeValuePrefixes, deviceAccessGroupIds, filters, areaIds);
  }

  getAlertsForChart(
    deviceAccessGroupIds: string[],
    selectedDateRange: Date[],
  ): Observable<AlertChartModel[]> {
    return this.create().getAlertsForChart(deviceAccessGroupIds, selectedDateRange);
  }

  getAlertRemarks(alertId: string) {
    return this.create().getAlertRemarks(alertId);
  }

  updateAlertStatus(
    alert: AlertViewModel,
    req: UpdateAlertStatusRequest,
  ): Observable<AlertViewModel> {
    return this.create().updateAlertStatus(alert, req);
  }

  shouldRefreshRemarks(alert: AlertViewModel): boolean {
    return this.create().shouldRefreshRemarks(alert);
  }

  private create(): BaseAlertDataService {
    if (this.predictiveMaintenance) {
      return this.predictiveMaintenanceAlertDataService;
    } else {
      return this.alertDataService;
    }
  }
}
