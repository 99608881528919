import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { GlobalErrorHandler } from './handlers/global-error.handler';
import { AppComponent } from './app.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslationLoaderFactory } from '@dpdhl-iot/shared';
import { SharedUiRootModule } from '@dpdhl/angular-shared-ui';
import {
  APPLICATION_CONFIG,
  APPLICATION_INSIGHTS_CONFIG,
  ApplicationConfig,
  ApplicationInsightsConfig,
  MAPS_CONFIG,
  MapsConfig,
  RELEASE_VERSION,
  ShellComponent,
  ShellRootModule,
} from '@dpdhl/iot-shared-ui';
import { ManagementModule } from './management/management.module';
import { BackendApiModule, BackendConfiguration } from '@dpdhl-iot/api/backend';
import { MSAL_CONFIG, MsalConfiguration } from '@dpdhl/angular-sso';
import { MsalRedirectComponent } from '@azure/msal-angular';
import {BaseChartDirective, provideCharts, withDefaultRegisterables} from "ng2-charts";

const getApplicationConfig = () =>
  //eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/dot-notation
  (window as any)['__applicationConfiguration'] as ApplicationConfig;
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SharedUiRootModule.forRoot({
      assetVersion: 'VERSION',
    }),
    ShellRootModule,
    ShellComponent,
    RouterModule.forRoot(
      [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'manage',
        },
        {
          path: '',
          loadChildren: () =>
            import('./air-quality/air-quality.module').then((m) => m.AirQualityModule),
        },
        { path: '**', redirectTo: 'manage' },
      ],
      { initialNavigation: 'enabledBlocking' },
    ),
    AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ManagementModule,
    BackendApiModule.forRoot(
      () =>
        new BackendConfiguration({
          basePath: `${getApplicationConfig().baseUris.backend}`,
        }),
    ),
    BaseChartDirective
  ],
  providers: [
    { provide: APPLICATION_CONFIG, useValue: getApplicationConfig() },
    { provide: RELEASE_VERSION, useValue: 'VERSION' },
    {
      provide: MSAL_CONFIG,
      //eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/dot-notation
      useValue: (window as any)['__msalConfig'] as MsalConfiguration,
    },
    {
      provide: APPLICATION_INSIGHTS_CONFIG,
      //eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/dot-notation
      useValue: (window as any)['__appInsightsConfig'] as ApplicationInsightsConfig,
    },
    {
      provide: MAPS_CONFIG,
      //eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/dot-notation
      useValue: (window as any)['__mapsConfig'] as MapsConfig,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    provideHttpClient(),
    provideCharts(withDefaultRegisterables())
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
