import { Injectable } from '@angular/core';
import { UserPreference } from './users.types';
import { EnvironmentalUnitSystem } from '@dpdhl/iot-shared-ui';
import { UserSettingValue } from '@dpdhl/iot-shared-ui/lib/iot-application-shell/api/settings-api';

export const DEFAULT_PREFERENCE = {
  dateRangeEndDays: 7,
  dateRangeStartDays: -28,
  timeZone: 'UTC',
  isDefault: true,
  unitSystem: EnvironmentalUnitSystem.METRIC,
};

@Injectable({
  providedIn: 'root',
})
export class UserPreferenceMapperService {
  toUserPreference(userSettingValues: UserSettingValue[]): UserPreference {
    const measurementSystem = userSettingValues.find((v) => v.definitionId === 'GLOBAL_MEASUREMENT_SYSTEM');

    let unitSystem = EnvironmentalUnitSystem.METRIC;
    if (measurementSystem) {
      unitSystem = measurementSystem.value.toLowerCase() as EnvironmentalUnitSystem;
    }

    return {
      ...DEFAULT_PREFERENCE,
    unitSystem,
    };
  }
}
