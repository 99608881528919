import { Injectable } from '@angular/core';
import { NotificationDataService, NotificationType } from '@dpdhl/iot-shared-ui';
import {
  AlertsDownloadRequest,
  FileDownloadResponse,
  FileType,
  FilterOperator,
} from '@dpdhl-iot/api/backend';
import { AlertManagementService, createDownloadFileBlob } from '@dpdhl-iot/shared';
import { getAlertStatusFiltersByTab } from './alert-management.function';
import { Observer, Subscription } from 'rxjs';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AlertDownloadService {
  constructor(
    private toastNotificationService: NotificationDataService,
    private translateService: TranslateService,
    private alertManagementService: AlertManagementService
  ) {}

  downloadAlerts(
    dateRange: Date[],
    deviceAccessGroupIds: string[] | null,
    selectedTab: number,
    downloadType: FileType,
    finaly: () => void
  ): Subscription {
    this.toastNotificationService.addNotification({
      text: this.translateService.instant('common.downloading') + '...',
      type: NotificationType.INFO,
    });
    const toDateTime = dateRange[1];
    toDateTime.setDate(toDateTime.getDate() + 1);
    const fromDate = dateRange[0].getTime();
    const toDate = toDateTime.getTime();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const alertsDownloadRequest: AlertsDownloadRequest = {
      pageSize: 5000,
      filters: [
        {
          colName: 'DeviceTimestamp',
          operator: FilterOperator.GREATER_EQUAL,
          colValue: fromDate,
        },
        {
          colName: 'DeviceTimestamp',
          operator: FilterOperator.LESS_THAN,
          colValue: toDate,
        },
      ],
      searchValue: '',
      cursor: '',
      sortOrder: 'desc',
      sortColumnName: 'DeviceTimestamp',
      fromDate,
      toDate,
      timeZone,
    };
    if (deviceAccessGroupIds?.length) {
      alertsDownloadRequest.filters!.push({
        colName: 'deviceAccessGroupId',
        operator: FilterOperator.IN,
        colValue: deviceAccessGroupIds,
      });
    }

    alertsDownloadRequest.filters!.push(...getAlertStatusFiltersByTab(selectedTab));

    const observer = this.getDownloadObserver(downloadType, finaly);

    return this.alertManagementService
      .downloadAlerts(downloadType, alertsDownloadRequest)
      .subscribe(observer);
  }

  private getDownloadObserver(
    downloadType: FileType,
    finaly: () => void
  ): Observer<FileDownloadResponse> {
    return {
      next: (result) => {
        const fileDownload = createDownloadFileBlob(result);
        if (downloadType === FileType.PDF) {
          saveAs(fileDownload, 'Alert.pdf');
        } else {
          saveAs(fileDownload, 'Alert.xlsx');
        }
        this.toastNotificationService.addNotification({
          text: this.translateService.instant('common.downloaded'),
          type: NotificationType.INFO,
        });
      },
      error: () => finaly(),
      complete: () => finaly(),
    };
  }
}
