import { DisplayUserModel } from '@dpdhl-iot/api/management';

export class DisplayUserViewModel implements DisplayUserModel {
  public id: string;
  public firstName: string;
  public lastName: string;
  public fullName: string;

  constructor(user: DisplayUserModel) {
    this.id = user.id;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.fullName = this.getUserFullName(user);
  }

  private getUserFullName = (user: DisplayUserModel) => `${user.firstName} ${user.lastName}`;
}
