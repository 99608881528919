import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CellAlertSeverity,
  CellAlertStatus,
  DeviceDetailsAlert,
  DeviceDetailsPredictiveMaintenance, LoadImage,
  PredictiveMaintenanceAlertDataService,
  PredictiveMaintenanceService,
} from '@dpdhl-iot/predictive-maintenance';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  filter,
  Observable,
  Subscription,
  Subject,
} from 'rxjs';
import { DateTime } from 'luxon';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AlertRemarkViewModel, AlertStatusType } from '@dpdhl-iot/shared';

@Component({
  selector: 'app-frequency-analysis-cell',
  templateUrl: './frequency-analysis-cell.component.html',
  styleUrls: ['./frequency-analysis-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FrequencyAnalysisCellComponent implements OnInit, OnDestroy {
  @Input() selectedCell = 0;
  @ViewChild('frequencyImage', { read: ElementRef }) frequencyImage: ElementRef;

  public predictiveDevices: DeviceDetailsPredictiveMaintenance[] = [];
  public noiseGradientImage?: SafeUrl;

  public alertForCell: DeviceDetailsAlert | undefined;

  validatorsCell = [Validators.min(0)];

  selectedDateRange = [
    DateTime.now().minus({ month: 1.25 }).toJSDate(),
    DateTime.now().endOf('day').toJSDate(),
  ];

  formGroup = new FormGroup({
    dateRange: new FormControl<Date[]>(this.selectedDateRange),
    deviceId: new FormControl<string>(''),
    cell: new FormControl<number>(0, [...this.validatorsCell]),
    colorContrast: new FormControl<number>(5),
  });

  frequencyRangeString: string[] = [];
  frequencyRange: number[] = [];
  frequencyDataCellTimes: number[] = [];
  noiseFrequencyRangeString: string[] = [];
  noiseFrequencyRangeStringScaled: string[] = [];
  noiseFrequencyRange: number[] = [];
  noiseFrequencyDataCellTimes: number[] = [];
  loading = true;
  selectedTab = 0;

  mouseOverText = '';

  private loadFrequencyDiagramProvider = new BehaviorSubject<LoadImage>({
    isLoading: true,
    imageData: null,
  });
  // eslint-disable-next-line @typescript-eslint/member-ordering
  loadFrequencyDiagram: Observable<LoadImage> = this.loadFrequencyDiagramProvider.asObservable();

  private loadNoiseAlertFrequencyDiagramProvider = new BehaviorSubject<LoadImage>({
    isLoading: true,
    imageData: null,
  });
  // eslint-disable-next-line @typescript-eslint/member-ordering
  loadNoiseAlertFrequencyDiagram: Observable<LoadImage> =
    this.loadNoiseAlertFrequencyDiagramProvider.asObservable();

  private loadNoiseWarningFrequencyDiagramProvider = new BehaviorSubject<LoadImage>({
    isLoading: true,
    imageData: null,
  });
  // eslint-disable-next-line @typescript-eslint/member-ordering
  loadNoiseWarningFrequencyDiagram: Observable<LoadImage> =
    this.loadNoiseWarningFrequencyDiagramProvider.asObservable();

  private readonly subs: Subscription[] = [];
  alertMarkGroups: { [p: number]: AlertRemarkViewModel[] } = {};

  private mouseMoveSubject = new Subject<MouseEvent | undefined>();

  constructor(
    private predictiveMaintenanceService: PredictiveMaintenanceService,
    private alertDataService: PredictiveMaintenanceAlertDataService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.formGroup.valueChanges.pipe(debounceTime(500)).subscribe(() => {
        this.updateFrequencyData();
        this.updateAlertTriggerData();
      }),
      this.formGroup.valueChanges
        .pipe(
          filter((val) => !!val && !!val.deviceId),
          distinctUntilChanged((a, b) => a.deviceId === b.deviceId),
          debounceTime(500),
        )
        .subscribe(() => {
          this.updateScale();
        }),
      this.formGroup.valueChanges
        .pipe(
          filter((val) => !!val && !!val.colorContrast),
          distinctUntilChanged((a, b) => a.colorContrast === b.colorContrast),
          debounceTime(500),
        )
        .subscribe(() => {
          this.updateNoiseGradient();
        }),
      this.predictiveMaintenanceService.filteredPredictiveMaintenanceDevices.subscribe((result) => {
        if (result.length > 0) {
          this.predictiveDevices = result;
          this.formGroup.patchValue({ cell: this.selectedCell ?? 0 });
          this.formGroup.patchValue({ deviceId: this.predictiveDevices[0].deviceId });
          this.validatorsCell = [
            Validators.min(0),
            Validators.max(this.predictiveDevices[0].sensor.beltCells! - 1),
          ];
          this.selectedCell = 0;
        }
      }),
      this.mouseMoveSubject
        .pipe(debounceTime(100))
        .subscribe((event) => this.overlayDateMouseMove(event)),
    );
  }

  updateFrequencyData() {
    this.loading = true;
    this.alertMarkGroups = {};
    this.loadFrequencyDiagramProvider.next({ isLoading: true, imageData: null });
    this.alertForCell = undefined;
    this.mouseOverText = '';
    const selectedCell = this.formGroup.value.cell!;
    const deviceId = this.formGroup.value.deviceId;
    if (deviceId) {
      this.subs.push(
        this.predictiveMaintenanceService
          .getCellFrequencyData(
            deviceId,
            selectedCell,
            this.formGroup.value.dateRange![0],
            this.formGroup.value.dateRange![1],
            this.formGroup.value.colorContrast!,
          )
          .subscribe((imageData) => {
            this.loading = false;
            if (imageData[0].byteLength > 0) {
              this.frequencyDataCellTimes = imageData[1];
              this.loadFrequencyDiagramProvider.next({ isLoading: false, imageData: imageData[0] });
              this.loadAlertHistory();
            }
          }),
      );
    }

    this.alertForCell = this.predictiveMaintenanceService.getAlertForCell(
      selectedCell,
      this.predictiveDevices.find((a) => a.deviceId === deviceId)?.alertCells,
    );
  }

  updateAlertTriggerData() {
    this.loadNoiseAlertFrequencyDiagramProvider.next({ isLoading: true, imageData: null });
    this.loadNoiseWarningFrequencyDiagramProvider.next({ isLoading: true, imageData: null });
    const selectedCell = this.formGroup.value.cell;
    const deviceId = this.formGroup.value.deviceId;
    if (deviceId && (selectedCell || selectedCell === 0)) {
      this.subs.push(
        this.predictiveMaintenanceService
          .getAlertTriggeringFactors(
            deviceId,
            selectedCell,
            this.formGroup.value.dateRange![0],
            this.formGroup.value.dateRange![1],
          )
          .subscribe((imageData) => {
            if (imageData[0].byteLength > 0) {
              this.noiseFrequencyDataCellTimes = imageData[2].timestamps!;
              this.noiseFrequencyRangeString = imageData[2].frequencies!;
              this.noiseFrequencyRangeStringScaled = this.noiseFrequencyRangeString.filter(
                (_, index) => (index + 1) % 10 === 0,
              );
              this.noiseFrequencyRangeStringScaled.unshift('0');
              this.noiseFrequencyRange = this.noiseFrequencyRangeString.map((n) => +n);
              this.loadNoiseAlertFrequencyDiagramProvider.next({
                isLoading: false,
                imageData: imageData[0],
              });
              this.loadNoiseWarningFrequencyDiagramProvider.next({
                isLoading: false,
                imageData: imageData[1],
              });
            }
          }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  mouseMove(event: MouseEvent) {
    this.mouseMoveSubject.next(event);
  }

  overlayDateMouseMove(event?: MouseEvent) {
    this.mouseOverText = '';
    if (!event) {
      return;
    }
    if (event.target instanceof Image && event.target.alt === 'frequency-diagram') {
      // Calculate Date to show underneath the frequency diagram
      const timeOffset = Math.trunc(
        (this.frequencyDataCellTimes.length * event.offsetX) / event.target.width,
      );
      const time = this.frequencyDataCellTimes[timeOffset];
      const timeText = time ? DateTime.fromMillis(time).toFormat('dd.MM.yyyy HH:mm:ss') : '';

      // Calculate frequencies to show underneath the frequency diagram
      const frequencyOffset =
        this.frequencyRange.length -
        (Math.trunc(((this.frequencyRange.length - 1) * event.offsetY) / event.target.height) + 2);
      const frequencyRange1 = this.frequencyRange[frequencyOffset];
      const frequencyRange2 = this.frequencyRange[frequencyOffset + 1];
      if (timeText && frequencyRange1 && frequencyRange2) {
        this.mouseOverText = `${timeText}  -  ${frequencyRange1}-${frequencyRange2} Hz`;
      }
    }
  }

  overlayDateMouseLeave() {
    this.mouseMoveSubject.next(undefined);

    this.mouseOverText = '';
  }

  private updateScale() {
    this.predictiveMaintenanceService.getScale(this.formGroup.value.deviceId!).subscribe((res) => {
      this.frequencyRangeString = res.map((n) => n.toString());
      this.frequencyRange = res;
    });
  }

  private updateNoiseGradient() {
    this.noiseGradientImage = undefined;
    this.predictiveMaintenanceService
      .getNoiseGradient(this.formGroup.value.colorContrast!)
      .subscribe((res) => {
        this.noiseGradientImage = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(new Blob([res])),
        );
      });
  }

  loadAlertHistory() {
    const selectedCell = this.formGroup.value.cell!;
    const deviceId = this.formGroup.value.deviceId;

    this.subs.push(
      this.alertDataService
        .loadAlertHistory(
          selectedCell,
          deviceId!,
          this.frequencyDataCellTimes[0],
          this.frequencyDataCellTimes[this.frequencyDataCellTimes.length - 1],
        )
        .subscribe((r) => {
          this.alertMarkGroups = r!;
        }),
    );
  }

  getMarginForImage(date: string): number {
    const elementWidth = this.frequencyImage.nativeElement.offsetWidth;
    const imageWidth = this.frequencyImage.nativeElement.querySelector('img')?.offsetWidth;

    const widthDiff = elementWidth - imageWidth;

    const times = this.frequencyDataCellTimes.filter(
      (a) => DateTime.fromMillis(a).startOf('day').toMillis() === +date,
    );
    const middleIndex = times
      .map((a) => this.frequencyDataCellTimes.indexOf(a))
      .reduce((acc, v, i, a) => acc + v / a.length, 0);

    return (
      widthDiff - 10 + Math.trunc((middleIndex * imageWidth) / this.frequencyDataCellTimes.length)
    );
  }

  getCountForAlertType(alertStatus: CellAlertStatus, alertRemarks: AlertRemarkViewModel[]): number {
    switch (alertStatus) {
      case CellAlertStatus.NewAlert:
        return alertRemarks.filter(
          (remark) =>
            remark.statusId == AlertStatusType.Open && remark.remarks == 'Alert Opened by System',
        ).length;
      case CellAlertStatus.NewWarning:
        return alertRemarks.filter(
          (remark) =>
            remark.statusId == AlertStatusType.Open && remark.remarks == 'Warning Opened by System',
        ).length;
      case CellAlertStatus.SeverityToAlert:
        return alertRemarks.filter(
          (remark) =>
            remark.statusId == AlertStatusType.Open &&
            remark.remarks == 'Severity changed to Alert',
        ).length;
      case CellAlertStatus.SeverityToWarning:
        return alertRemarks.filter(
          (remark) =>
            remark.statusId == AlertStatusType.Open &&
            remark.remarks == 'Severity changed to Warning',
        ).length;
      case CellAlertStatus.AutomaticallyResolved:
        return alertRemarks.filter((remark) => remark.statusId == AlertStatusType.Resolved).length;
      case CellAlertStatus.ManuallyResolved:
        return alertRemarks.filter((remark) => remark.statusId == AlertStatusType.ManuallyClosed)
          .length;
      default:
        return 0;
    }
  }

  tabSelected(tabIndex: number) {
    if (this.selectedTab === tabIndex) {
      return;
    }
    this.selectedTab = tabIndex;
  }

  protected readonly Date = Date;
  protected readonly CellAlertSeverity = CellAlertSeverity;
  protected readonly CellAlertStatus = CellAlertStatus;
}
