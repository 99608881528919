import {FacilityInfo, Org, SubOrg} from '@dpdhl-iot/api/backend';
import {legendColorClassByKey, StatusColorClass} from '../../global-functions/facility.function';
import {Injectable} from '@angular/core';
import {
  CellAlertSeverity,
  DeviceDetailsPredictiveMaintenance,
  PredictiveMaintenanceService,
} from '@dpdhl-iot/predictive-maintenance';
import {DefaultViewConfiguration, ViewConfigurationModel, ViewConfigurationService,} from '@dpdhl-iot/shared';

@Injectable({
  providedIn: 'root',
})
export class FacilityAreaViewService {
  private predictiveMaintenanceDevices: DeviceDetailsPredictiveMaintenance[] = [];
  private viewConfiguration: ViewConfigurationModel = DefaultViewConfiguration;
  constructor(
    private viewConfigurationService: ViewConfigurationService,
    private predictiveMaintenanceService: PredictiveMaintenanceService,
  ) {
    viewConfigurationService.currentViewConfiguration$.subscribe(
      (viewConfiguration) => (this.viewConfiguration = viewConfiguration),
    );
    predictiveMaintenanceService.predictiveMaintenanceDevices.subscribe((result) => {
      this.predictiveMaintenanceDevices = result;
    });
  }

  public facilityLegendColorClass = (facility: Org): string =>
    this.statusLegendColor(
      facility.isSelectable!,
      facility.totalActiveDeviceCount! > 0,
      facility.totalOpenAlertsCount! > 0,
      facility.totalAcceptedAlertsCount! > 0,
      facility.totalResolvingAlertsCount! > 0,
    );

  public facilityInfoLegendColorClass = (facility: FacilityInfo): StatusColorClass =>
    this.statusLegendColor(
      facility.isSelectable!,
      facility.totalActiveDevices! > 0,
      facility.hasOpenAlerts!,
      facility.hasAcceptedAlerts!,
      facility.hasResolvingAlerts!,
    );

  public areaLegendColorClass(area: SubOrg, prefix: string): string {
    let colorKey: StatusColorClass;
    if (this.viewConfiguration.facilityView.show_predictiveMaintenance) {
      const sensors = this.getPredictiveSensorsForArea(area);
      if (sensors.length === 0) {
        colorKey = StatusColorClass.Grey;
      } else {
        const alertCells = new Set<number>(sensors.map((a) => a.alertCells).flat().filter(a => a.alertState === CellAlertSeverity.ALERT).map(a => a.cell));
        const warningCells = new Set<number>(sensors.map((a) => a.alertCells).flat().filter(a => a.alertState === CellAlertSeverity.WARNING).map(a => a.cell));
        if (sensors.find((a) => a.isActive === false)) {
          colorKey = StatusColorClass.Grey;
        } else if (alertCells.size > 0) {
          colorKey = StatusColorClass.Red;
        } else if (warningCells.size > 0) {
          colorKey = StatusColorClass.Yellow;
        } else {
          colorKey = StatusColorClass.Green;
        }
      }
    } else {
      colorKey = this.statusLegendColor(
        true,
        area.activeDeviceCount! > 0,
        area.totalOpenAlertsCount! > 0,
        area.totalAcceptedAlertsCount! > 0,
        area.totalResolvingAlertsCount! > 0,
      );
    }
    return `${prefix}-${legendColorClassByKey(colorKey)}`;
  }
  public statusLegendColor = (
    isSelectable: boolean,
    hasActiveDevices: boolean,
    hasOpenAlerts: boolean,
    hasAcceptedAlerts: boolean,
    hasResolvingAlerts: boolean,
  ): StatusColorClass => {
    if (!isSelectable) {
      return StatusColorClass.Grey;
    } else if (hasOpenAlerts || hasAcceptedAlerts) {
      return StatusColorClass.Red;
    } else if (!hasOpenAlerts && !hasAcceptedAlerts && hasResolvingAlerts) {
      return StatusColorClass.Yellow;
    } else if (hasActiveDevices && !hasOpenAlerts && !hasAcceptedAlerts && !hasResolvingAlerts) {
      return StatusColorClass.Green;
    } else if (!hasActiveDevices && !hasOpenAlerts && !hasAcceptedAlerts && !hasResolvingAlerts) {
      return StatusColorClass.Grey;
    }
    return StatusColorClass.Pink;
  };

  getPredictiveSensorsForArea(area: SubOrg): DeviceDetailsPredictiveMaintenance[] {
    return this.predictiveMaintenanceDevices.filter(
      (a) => a.sensor?.deviceAccessGroup === area.deviceGroupId,
    );
  }
}
